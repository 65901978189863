'use client';

import { InstagramLogoIcon, TwitterLogoIcon } from '@radix-ui/react-icons';
import { BreakpointSwitch } from '@spikemark/ui/components/breakpoint-switch';
import { BaseLink as Link } from '@spikemark/ui/components/navigation/entity-links';
import { Button } from '@spikemark/ui/components/ui/button';
import { TaylorMadeLogo } from '@spikemark/ui/partnerships/taylor-made';
import { FC, useCallback } from 'react';
const containerClassName = 'flex flex-col';
const sectionWrapperClassName = 'text-sm leading-loose font-light mb-8 space-y-1 md:mb-0';
const headerClassName = 'uppercase text-base font-bold mb-2 text-[#A7ADB5] md:mb-3';
export const Footer: FC<{
  advertisingEnabled?: boolean;
  sponsorshipEnabled?: boolean;
}> = ({
  advertisingEnabled,
  sponsorshipEnabled
}) => {
  const handlePreferencesClick = useCallback(() => {
    if ('displayPreferenceModal' in window && typeof window.displayPreferenceModal === 'function') {
      window.displayPreferenceModal();
    }
  }, []);
  return <footer className="bg-primary-dark text-white print:hidden px-12 py-8 md:px-24 md:py-14 mt-4 lg:mt-8" data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      {sponsorshipEnabled && <div className="pt-2 md:pt-4 pb-10 lg:pb-20">
          <BreakpointSwitch breakpoint="md" fallback={<TaylorMadeLogo variant="large" dark />}>
            <TaylorMadeLogo variant="xlarge" dark />
          </BreakpointSwitch>
        </div>}
      <div className="flex flex-col text-sm md:flex-row md:justify-between">
        <div className={containerClassName}>
          <h1 className={headerClassName}>Information</h1>
          <div className={sectionWrapperClassName}>
            <Link className="block hover:underline" href="/rankings" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              National Collegiate Golf Ranking
            </Link>
            <Link className="block hover:underline" href="/information/faqs" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              Frequently Asked Questions
            </Link>
            <Link className="block hover:underline" href="/information/privacy" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              Privacy Policy
            </Link>
            <Link className="block hover:underline" href="/information/tos" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              Terms of Service
            </Link>
            {advertisingEnabled && <>
                <Link className="block hover:underline" href="/information/cookie-policy">
                  Cookie Policy
                </Link>
                <Button variant="link" className="block text-white p-0 h-auto termly-display-preferences" onClick={handlePreferencesClick}>
                  Consent Preferences
                </Button>
              </>}
          </div>
        </div>

        <div className={containerClassName}>
          <h1 className={headerClassName}>Company</h1>
          <div className={sectionWrapperClassName}>
            <Link className="block hover:underline" href="/information/about" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              About us
            </Link>
            <Link className="block hover:underline" href="/information/media" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              Press
            </Link>
            <Link className="block hover:underline" target="_blank" href="https://clippd.com" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              Clippd.com
            </Link>
          </div>
        </div>

        <div className={containerClassName}>
          <h1 className={headerClassName}>Contact Us</h1>
          <div className={sectionWrapperClassName}>
            <p>Clippd USA Ltd</p>
            <p>
              mail:{' '}
              <Link href="mailto:support@clippd.io" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                support@clippd.io
              </Link>
            </p>
          </div>
        </div>

        <div className={containerClassName}>
          <h1 className={headerClassName}>Follow Us</h1>
          <div className="flex space-x-3 mb-4">
            <Link href="https://www.instagram.com/clippdscoreboard" target="_blank" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              <InstagramLogoIcon className="w-6 h-6 text-white" data-sentry-element="InstagramLogoIcon" data-sentry-source-file="footer.tsx" />
            </Link>
            <Link href="https://twitter.com/clippdscore" target="_blank" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
              <TwitterLogoIcon className="w-6 h-6 text-white" data-sentry-element="TwitterLogoIcon" data-sentry-source-file="footer.tsx" />
            </Link>
          </div>
        </div>
      </div>
    </footer>;
};